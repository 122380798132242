<template>
  <span class="full-width" @mouseover="checkIfTextIsOverflowed">
    <uds-tooltip v-if="isTextOverflowed" :tooltip-text="tooltipText">
      <div ref="container" class="truncate-text">
        {{ text }}
      </div>
    </uds-tooltip>
    <div v-else ref="container" class="truncate-text">
      {{ text }}
    </div>
  </span>
</template>

<script setup lang="ts">
const props = defineProps<{
  text: string;
}>();

const container: Ref<any> = ref(null);

const isTextOverflowed: Ref<boolean> = ref(false);

const MAX_TEXT_LENGTH_ONE_LINE = 32;
const tooltipText = computed(() => {
  if (props.text.length > MAX_TEXT_LENGTH_ONE_LINE) {
    const atIndex = props.text.indexOf("@") + 1;
    return `${props.text.slice(0, atIndex)}<br />${props.text.slice(atIndex)}`;
  }

  return props.text;
});

function checkIfTextIsOverflowed() {
  const offsetWidth = container.value?.offsetWidth;
  const scrollWidth = container.value?.scrollWidth;
  if (!isDefined(offsetWidth) || !isDefined(scrollWidth)) {
    return (isTextOverflowed.value = false);
  }

  return (isTextOverflowed.value = offsetWidth < scrollWidth);
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.truncate-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: 1.1 !important;
}
</style>
